import './About.css';

const WelcomeTyping = () => {
  return (
    <div className="welcome">
      <h2>Hi, I'm Ryan</h2>
    </div>
  );
};

export default WelcomeTyping;
